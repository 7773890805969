export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')
];

export const server_loads = [0];

export const dictionary = {
		"/": [5],
		"/Temp-Format": [27],
		"/auth": [28],
		"/auth/forgot-password": [29],
		"/auth/lti-add-unit": [30],
		"/auth/signup": [31],
		"/auth/update-password": [32],
		"/dev": [33],
		"/dev/gifs": [34],
		"/dev/projects": [35],
		"/dev/table": [36],
		"/(app)/feedback/[survey]": [6,[2]],
		"/(app)/logging-in": [7],
		"/logout": [~37],
		"/(app)/my-uni": [8,[2]],
		"/(app)/my-uni/[unit]": [9,[2]],
		"/(app)/my-uni/[unit]/details": [11,[2]],
		"/(app)/my-uni/[unit]/subgroup/[subgroupId]": [12,[2]],
		"/(app)/my-uni/[unit]/subgroup/[subgroupId]/details": [14,[2]],
		"/(app)/my-uni/[unit]/subgroup/[subgroupId]/[post]": [13,[2]],
		"/(app)/my-uni/[unit]/[post]": [10,[2],[,3]],
		"/(app)/network": [15,[2]],
		"/(app)/network/dms": [16,[2,4]],
		"/(app)/network/dms/group/[subgroupId]": [19,[2,4]],
		"/(app)/network/dms/[friendId]": [17,[2,4]],
		"/(app)/network/dms/[friendId]/details": [18,[2,4]],
		"/(app)/notifications": [20,[2]],
		"/(app)/profile/AddExperience": [21,[2]],
		"/(app)/profile/EditProfilePic": [22,[2]],
		"/(app)/profile/me": [24,[2]],
		"/(app)/profile/me/edit": [25,[2]],
		"/(app)/profile/settings": [26,[2]],
		"/(app)/profile/[profileId]": [23,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';